<template>
  <div>
    <v-row>
      <v-col cols="6" class="page-header">
        <h2 class="page-title">Customers</h2>
      </v-col>
      <v-col cols="6" class="d-flex align-center">
        <v-btn class="ml-auto" color="primary" @click="$router.push({ name: 'website.customer.create' })">
          + Add customer
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="elevation-2 elevation-form">
      <v-col cols="12">
        <Resource
          :document="document"
          :filter-fields="null"
          :filter-data="filterData"
          :filter-callback="setFilterData"
          :searchable="searchableField"
          :columns="columns"
          :update-router-callback="getUpdateRouterData"
          :routeName="updateRouteName"
        />
        <!-- <pagination :page.sync="page"></pagination> -->
        <v-col cols="12" class="d-flex justify-space-between">
          <limit-page :callback="setLimit" />
          <pagination
            :visible="0"
            :length="1000000"
            v-if="!$parent.isLoading && document && document.length > 0"
            :page.sync="page"
          ></pagination>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Resource from '@/components/Resources';
import resourceListMixin from '@/mixins/resource-list';

export default {
  components: { Resource },
  mixins: [resourceListMixin],
  data() {
    return {
      resourceType: 'customer',
      columns: {
        // Selectable: {
        //   blankLabel: true,
        //   kind: 'selectable',
        //   width: 1,
        // },
        Email: {
          field: 'email',
          kind: 'view',
        },
        Address: {
          field: 'address1',
          kind: 'text',
        },
        Order: {
          field: 'totalOrder',
          kind: 'text',
        },
        Spend: {
          field: 'totalPayment',
          kind: 'text',
        },
        // Actions: {
        //   kind: 'actions',
        // },
      },
      updateRouteName: 'website.customer.update',
      searchableField: 'title_search',
      sortable: [],
    };
  },
};
</script>
